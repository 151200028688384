import {whatsappUrl} from '@/constants';
import {FaceFrownIcon} from '@heroicons/react/24/outline';
import {Link, usePage} from '@inertiajs/react';
import {
    InstagramLogoIcon,
    LinkedInLogoIcon,
    TwitterLogoIcon,
} from '@radix-ui/react-icons';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {
    FaFacebook,
    FaFacebookSquare,
    FaTelegram,
    FaTiktok,
    FaYoutube,
} from 'react-icons/fa';

export default function () {
    const {setting, currentLang}: any = usePage().props;
    const {t} = useTranslation();
    const navigation = [
        {
            name: t('home'),
            href: route(`${currentLang}.frontend.home`),
            value: null,
        },
        {
            name: t('teachers'),
            href: route(`${currentLang}.frontend.user.index`),
            value: null,
        },
        {
            name: t('courses'),
            href: route(`${currentLang}.frontend.course.index`),
            value: null,
        },
        {
            name: t('aboutus'),
            href: route(`${currentLang}.frontend.aboutus`),
            value: null,
        },
        {
            name: t('terms'),
            href: route(`${currentLang}.frontend.terms`),
            value: null,
        },
        {
            name: t('privacy_policy'),
            href: route(`${currentLang}.frontend.policy`),
            value: null,
        },
        {
            name: t('contactus'),
            href: route(`${currentLang}.frontend.contactus`),
            value: null,
        },
        {
            name: t('trees'),
            href: route(`${currentLang}.frontend.tree.index`),
            value: null,
        },
    ];
    return (
        <footer className="print:hidden mx-auto  overflow-hidden py-4 lg:py-5  w-full">
            <div className="bg-white  flex flex-col rounded-t-3xl py-4">
                <div className="flex lg:flex-1 justify-center items-center">
                    <Link
                        preserveScroll={false}
                        href={route(`${currentLang}.frontend.home`)}
                    >
                        <span className="sr-only">Your Company</span>
                        <img
                            className="h-20 w-20 object-contain"
                            src={setting.thumb}
                            alt={setting.name}
                        />
                    </Link>
                </div>
                <nav
                    className="m-6 columns-2 lg:flex lg:justify-center lg:gap-x-12"
                    aria-label="Footer"
                >
                    {navigation.map((item) => (
                        <div key={item.name} className="pb-6">
                            <Link
                                preserveScroll={false}
                                href={item.href}
                                className="text-sm leading-6 text-theme-600 hover:text-theme-800 hover:underline"
                            >
                                {t(item.name)}
                            </Link>
                        </div>
                    ))}
                    <div className="pb-6">
                        <a
                            target="_blank"
                            href={whatsappUrl(
                                setting.hr_mobile ?? setting.whatsapp,
                            )}
                            className="text-sm leading-6 text-theme-600 hover:text-theme-800 hover:underline"
                        >
                            {t('employment')}
                        </a>
                    </div>
                    <div className="pb-6">
                        <a
                            target="_blank"
                            href={`${whatsappUrl(setting.mobile)}`}
                            className="text-sm leading-6 text-theme-600 hover:text-theme-800 hover:underline"
                        >
                            {t('technical_support')}
                        </a>
                    </div>
                </nav>
                <div className="mb-4 flex justify-center gap-x-4 xl:gap-x-8">
                    {setting.twitter && (
                        <a
                            target="blank"
                            href={setting.twitter}
                            className="text-prim-400 hover:text-prim-800"
                        >
                            <span className="sr-only">{t('twitter')}</span>
                            <TwitterLogoIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </a>
                    )}
                    {setting.linked && (
                        <a
                            target="blank"
                            href={setting.linked}
                            className="text-prim-400 hover:text-prim-800"
                        >
                            <span className="sr-only">{t('linked')}</span>
                            <LinkedInLogoIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </a>
                    )}
                    {setting.facebook && (
                        <a
                            target="blank"
                            href={setting.facebook}
                            className="text-prim-400 hover:text-prim-800"
                        >
                            <span className="sr-only">{t('facebook')}</span>
                            <FaFacebookSquare
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </a>
                    )}
                    {setting.instagram && (
                        <a
                            target="blank"
                            href={setting.instagram}
                            className="text-prim-400 hover:text-prim-800"
                        >
                            <span className="sr-only">{t('instagram')}</span>
                            <InstagramLogoIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </a>
                    )}
                    {setting.tiktok && (
                        <a
                            target="blank"
                            href={setting.tiktok}
                            className="text-prim-400 hover:text-prim-800"
                        >
                            <span className="sr-only">{t('tiktok')}</span>
                            <FaTiktok className="h-6 w-6" aria-hidden="true" />
                        </a>
                    )}
                    {setting.telegram && (
                        <a
                            target="blank"
                            href={setting.telegram}
                            className="text-prim-400 hover:text-prim-800"
                        >
                            <span className="sr-only">{t('telegram')}</span>
                            <FaTelegram
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </a>
                    )}
                    {setting.youtube && (
                        <a
                            target="blank"
                            href={setting.youtube}
                            className="text-prim-400 hover:text-prim-800"
                        >
                            <span className="sr-only">{t('youtube')}</span>
                            <FaYoutube className="h-6 w-6" aria-hidden="true" />
                        </a>
                    )}
                    {setting.telegram && (
                        <a
                            target="blank"
                            href={setting.telegram}
                            className="text-prim-400 hover:text-prim-800"
                        >
                            <span className="sr-only">{t('telegram')}</span>
                            <FaTelegram
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </a>
                    )}
                </div>
            </div>

            <div className="flex w-full justify-center items-center bg-gradient-to-r from-black to-teal-700 rounded-b-3xl py-6">
                <p className="text-center text-xs text-white">
                    &copy;{' '}
                    {`${moment().format('Y')} ${setting.name} ${t('all_rights_reserved')}`}
                </p>
            </div>
        </footer>
    );
}
